import React from 'react';
import InvestorLogin from '@/dealroadshow/ui/components/Landing/Institutional/InvestorLogin';
import LandingLayout from '@/dealroadshow/ui/components/Landing/Layout/LandingLayout';
import withGlobalProps from '@/Framework/Router/Next/withGlobalProps';

// add it when start work on language detect
// <I18nService container={ container } config={ config.i18 } locale={ 'ja' } />
// https://finsight.myjetbrains.com/youtrack/issue/FIN-27890

export const getServerSideProps = withGlobalProps(() => ({ props: {} }));

export default (): JSX.Element => (
  <LandingLayout>
    <InvestorLogin />
  </LandingLayout>
);
