import Head from 'next/head';
import { useTranslation, Trans } from '@/Framework/i18n';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import InvestorLoginForm from '../InvestorLoginForm';
import bottomPattern from '@/dealroadshow/ui/assets/bottomPattern.svg';
import config from '@/dealroadshow/application/config/config';
import { namespaces } from '@/dealroadshow/ui/locales/namespaces';
import ja from './locales/ja.json';
import en from './locales/en.json';
import styles from './investorLogin.scss';

function InvestorLogin() {
  const { t } = useTranslation(namespaces.INVESTOR_LOGIN, { en, ja });
  return (
    <div className={ styles.pageWrapper }>
      <Head>
        <title>Experience a Better Roadshow | Deal Roadshow</title>
        <meta
          name="description"
          content="Secure, SEC compliant electronic roadshow solution that offers better tools for better execution of
          debt, equity, IPO and municipal roadshows."
        />
      </Head>
      <div className={ styles.intro }>
        <InvestorLoginForm />
        <h2 className={ styles.introTitle }>
          { t('introTitle') }
        </h2>
        <p className={ styles.introDescription }>
          <Trans i18nKey={ `${ namespaces.INVESTOR_LOGIN }:introDescription` } components={ { strong: <strong /> } } />
        </p>
        <div className={ styles.bookDemoLinkWrp }>
          <BookDemoButton
            tenant={ config.code }
            buttonComponent="a"
            className={ styles.bookDemoLink }
            title={ t('linkBookDemo') }
          />
        </div>
      </div>
      <div className={ styles.bottomPattern }>
        <img src={ bottomPattern } />
      </div>
    </div>
  );
}

export default InvestorLogin;
